








































import { Component, Vue } from "vue-property-decorator";
import { api } from "@/api";

@Component
export default class Contact extends Vue {
  public name = null;
  public email = null;
  public message = null;

  public async submitForm() {
    const payload = {
      name: this.name,
      email: this.email.toLowerCase(),
      body: this.message
    };
    const response = await api.submitContactForm(payload);
    if (response) {
      this.name = null;
      this.email = null;
      this.message = null;
      this["$toast"].add({
        severity: "success",
        summary: "Contact form submitted!",
        detail: "We will reach out to you soon",
        life: 3000
      });
    } else {
      this["$toast"].add({
        severity: "error",
        summary: "Something went wrong...",
        detail: "Could not submit form response",
        life: 3000
      });
    }
  }
}
